import axios from 'axios';
import JwtDecode from 'jwt-decode';

export enum SystemType {
  Enlighten = 'enlighten',
  Track = 'track',
  Tarsim = 'tarsim',
}
interface PayloadType {
  user_id: number;
  exp: number;
  company: string;
  role: string;
}
export class UserCredientals {
  userId: number;
  exp: Date;
  userCompany: string;
  role: string;

  constructor(token: string) {
    const decoded: PayloadType = JwtDecode(token);
    //console.log(decoded);
    this.userId = decoded.user_id;
    this.userCompany = decoded.company;
    this.role = decoded.role;
    //  The 0 there is the key, which sets the date to the epoch
    this.exp = new Date(0);
    // type must be numericdate
    this.exp.setUTCSeconds(decoded.exp);
  }

  isExpired() {
    return new Date() > this.exp;
  }
}

export default class TStorage {
  static setUserId(userId: number) {
    localStorage.setItem('userId', `${userId}`);
  }

  static setLang(value: string) {
    localStorage.setItem('i18nextLng', value);
  }

  static getLang(): string {
    const lng = localStorage.getItem('i18nextLng') || 'tr';

    /*if (lng === null) {
      localStorage.setItem('i18nextLng', 'tr');
      lng = 'tr';
    }*/
    return lng;
  }

  static setSystem(system: string) {
    localStorage.setItem('system', system);
  }

  static getSystem(): SystemType {
    switch (localStorage.getItem('system')) {
      case SystemType.Track:
        return SystemType.Track;
      case SystemType.Enlighten:
        return SystemType.Enlighten;
      case SystemType.Tarsim:
        return SystemType.Tarsim;
      default:
        return SystemType.Track;
    }
  }

  static getLawOk() {
    return localStorage.getItem('lawok');
  }

  static setLawOk() {
    localStorage.setItem('lawok', 'ok');
  }

  static isLawOk(): boolean {
    return this.getLawOk() === 'ok';
  }

  static getUserId() {
    return localStorage.getItem('userId');
  }

  static setUserToken(userToken: string) {
    localStorage.setItem('userToken', userToken);
  }

  static getUserCredentials(): UserCredientals | null {
    const userToken = localStorage.getItem('userToken');
    return userToken ? new UserCredientals(userToken) : null;
  }

  static clear() {
    localStorage.clear();
    localStorage.setItem('showWhatsNew', 'false');
  }

  static setBearer() {
    const token = localStorage.getItem('userToken');
    if (token) axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  }
  static setCompanyHeader() {
    //TODO melih abiye sor- company=track mi kalsın tarla olarak değişsin mi?
    //axios.defaults.headers.common.Company = process.env.REACT_APP_COMPANY;
    axios.defaults.headers.common.Company = this.getUserCredentials()?.userCompany;
  }

  static getUserToken() {
    return localStorage.getItem('userToken');
  }
}
