import axios from 'axios';
import { Dispatch } from 'redux';
import { AppAction } from './index';

export const SEND_MAIL = 'MAIL/SEND';
export const SET_LOCATION = 'MAIL/LOCATION';
export const SET_DATE = 'MAIL/DATE';
export const SET_EMAIL = 'MAIL/MAIL';
export const RESET_ALL = 'MAIL/RESET';

export interface SendMailState {
  isSend: boolean;
  location: string;
  date: any;
  email: string;
}

const initialState: SendMailState = {
  isSend: false,
  location: '',
  date: null,
  email: '',
};

export default (state = initialState, action: AppAction) => {
  switch (action.type) {
    case SEND_MAIL:
      return {
        ...state,
        isSend: true,
      };
    case SET_EMAIL:
      return {
        ...state,
        email: action.payload,
      };
    case SET_LOCATION:
      return {
        ...state,
        location: action.payload,
      };
    case SET_DATE:
      return {
        ...state,
        date: action.payload,
      };
    case RESET_ALL:
      return {
        ...state,
        isSend: false,
        location: '',
        date: null,
        email: '',
      };
    default:
      return state;
  }
};

export const setMail = (email: string) => (dispatch: Dispatch<AppAction>) => {
  dispatch({
    type: SET_EMAIL,
    payload: email,
  });
};

export const setDate = (date: any) => (dispatch: Dispatch<AppAction>) => {
  dispatch({
    type: SET_DATE,
    payload: date,
  });
};

export const setLocation = (location: string) => (dispatch: Dispatch<AppAction>) => {
  dispatch({
    type: SET_LOCATION,
    payload: location,
  });
};

export const resetAll = () => (dispatch: Dispatch<AppAction>) => {
  dispatch({
    type: RESET_ALL,
  });
};

export const sendMailTo = (email: string, _location?: string, _date?: any) => (dispatch: Dispatch<AppAction>) => {
  let location = _location;
  let date = _date;
  if (typeof location === 'undefined') {
    location = 'Yer Belirtilmedi';
  }

  if (typeof date === 'undefined') {
    date = 'Tarih Belirtilmedi';
  } else {
    date = date.format('DD/MM/YYYY');
  }
  axios
    .post('http://log.tarla.io', {
      project_name: 'tarlain',
      log: `${email} / ${location} / ${date}`,
      severity: 'warning',
      channels: [
        {
          name: 'email',
          email: 'hello@tarla.io',
        },
      ],
    })
    .then(req => console.log(req))
    .catch(err => console.log(err));

  axios
    .post('http://log.tarla.io', {
      project_name: 'tarlain',
      log: `${email} / ${location} / ${date}`,
      severity: 'warning',
      channels: [
        {
          name: 'email',
          email: 'muhammet.ozturk@hacettepe.edu.tr',
        },
      ],
    })
    .then(req => console.log(req))
    .catch(err => console.log(err));

  dispatch({
    type: SEND_MAIL,
  });
};
