import React from 'react';
import { Button, ButtonProps } from 'semantic-ui-react';
import './NormalButton.css';

export interface Props {
  secondary?: boolean;
  primary?: boolean;
  text?: string;
  logo?: string;
}

const NormalButton = ({ primary, secondary, text, ...semanticProps }: Props & ButtonProps) => (
  <Button {...semanticProps} className={`${secondary ? 'secondary-background' : 'primary-background'} white-color`}>
    {text}
  </Button>
);

const EmptyButton = ({ primary = true, secondary = false, text, ...semanticProps }: Props & ButtonProps) => (
  <Button
    {...semanticProps}
    className={`${
      secondary ? 'secondary-border secondary-color' : 'primary-border primary-color'
    } transparent-background pes`}
  >
    {text}
  </Button>
);

const LogoButton = ({ primary = true, secondary = false, text, logo, ...semanticProps }: Props & ButtonProps) => (
  <Button {...semanticProps} className={`${secondary ? 'secondary-background' : 'primary-background'} white-color`}>
    <div className="u-flex u-flexJustifyBetween u-flexAlignItemsCenter">
      <img className="left-icon u-marginLm" alt="tree" src={logo} />
      <div className="u-flexGrow1 u-textCenter">{text}</div>
    </div>
  </Button>
);

const BlackLogoButton = ({ primary = true, secondary = false, text, logo, ...semanticprops }: Props & ButtonProps) => (
  <Button {...semanticprops} className="transparent-background black-border font-primary-color">
    <div className="u-flex u-flexJustifyBetween u-flexAlignItemsCenter">
      <img className="left-icon u-marginLm" alt="tree" src={logo} />
      <div className="u-flexGrow1 u-textCenter">{text}</div>
    </div>
  </Button>
);

export { NormalButton, EmptyButton, LogoButton, BlackLogoButton };
