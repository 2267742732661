import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
import langDedector from 'i18next-browser-languagedetector';
import {initReactI18next} from 'react-i18next';
import TStorage from './storage/TStorage';

const detectionOptions = {
  // order and from where user language should be detected
  order: ['querystring', 'cookie', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],

  // keys or params to lookup language from
  lookupQuerystring: 'lng',
  lookupCookie: 'i18next',
  lookupLocalStorage: 'i18nextLng',
  lookupSessionStorage: 'i18nextLng',
  lookupFromPathIndex: 0,
  lookupFromSubdomainIndex: 0,

  // cache user language on
  caches: ['localStorage', 'cookie'],
  excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)

  // optional htmlTag with lang attribute, the default is:
  htmlTag: document.documentElement,

  // optional set cookie options, reference:[MDN Set-Cookie docs](https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie)
  cookieOptions: { path: '/', sameSite: 'strict' },
};

i18n
  .use(Backend)
  .use(langDedector)
  .use(initReactI18next)
  .init({
    nsSeparator: ':::',
    keySeparator: '::',
    backend: {
      loadPath: '/tarlain_locales/{{lng}}/{{ns}}006.json',
    },
    lng: TStorage.getLang(),
    fallbackLng: TStorage.getLang() || 'tr',
    // have a common namespace used around the full app
    ns: ['translations', 'dashboard'],
    defaultNS: 'translations',

    detection: detectionOptions,
    debug: false,
    interpolation: {
      escapeValue: false, // not needed for react!!
    },

    react: {
      wait: false,
    },
  });

export default i18n;
