import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { createBrowserHistory } from 'history';
import rootReducer from './modules';

const enhancers = [];

if (process.env.NODE_ENV === 'development') {
  // eslint-disable-next-line no-underscore-dangle
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;
  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension());
  }
}

/**
 *  set history
 *  Details https://www.npmjs.com/package/history
 */
export const history = createBrowserHistory();

/**
 * Redux Thunk for async reducers.
 * @type {any[]}
 */
const middleware = [thunk];
const composedEnhancers = compose(
  applyMiddleware(...middleware),
  ...enhancers,
);
/**
 * Wtf this, i dont know . It just works.
 * @type {{}}
 */
const initialState = {};
export default createStore(rootReducer, initialState, composedEnhancers);
