import { combineReducers } from 'redux';
import sendMail, { SendMailState } from './sendmail';
import getStarted, { GetStartedState } from './getstarted';
import navbar, { NavbarState } from './navbar';

export interface AppAction {
  type: string;
  payload?: any;
}

export interface AppState {
  getStarted: GetStartedState;
  sendMail: SendMailState;
  navbar: NavbarState;
}

export default combineReducers<AppState>({
  getStarted,
  sendMail,
  navbar,
});
