import React, {Fragment, FunctionComponent, useState} from 'react';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {NormalButton} from '../components/semanticover/button/CustomButton';
import TStorage from '../storage/TStorage';

interface Props {}
// https://www.act-on.com/resources/gdpr/
const Gdpr: FunctionComponent<Props> = props => {
  const [t] = useTranslation('dashboard');
  const [isShow, setShow] = useState(true);
  return !TStorage.isLawOk() && isShow ? (
    <div id="law-ok">
      <span className="u-paddingRs">{t('cookie_text')}</span>
      <Link to={{ pathname: '/dashboard/company', state: { selectedId: 4 } }}>{t('Read More')}</Link>
      <span className="u-marginLs">
        <NormalButton
          onClick={() => {
            TStorage.setLawOk();
            setShow(false);
          }}
          secondary
          text={t('I accept')}
        />
      </span>
    </div>
  ) : (
    <Fragment />
  );
};

export default Gdpr;
