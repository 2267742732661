import { Dispatch } from 'redux';
import { AppAction } from './index';

export const SHOW_GET_STARTED = 'GET_STARTED/SHOW';
export const CLOSE_GET_STARTED = 'GET_STARTED/CLOSE';
export const TOGGLE_GET_STARTED = 'GET_STARTED/TOGGLE';

export interface GetStartedState {
  isShow: boolean;
}

const initialState: GetStartedState = {
  isShow: false,
};

export default (state = initialState, action : AppAction) => {
  switch (action.type) {
    case SHOW_GET_STARTED:
      return {
        ...state,
        isShow: true,
      };
    case CLOSE_GET_STARTED:
      return {
        ...state,
        isShow: false,
      };
    case TOGGLE_GET_STARTED:
      return {
        ...state,
        isShow: !state.isShow,
      };

    default:
      return state;
  }
};

export const showGetStarted = () => (dispatch: Dispatch<AppAction>) => {
  dispatch({
    type: SHOW_GET_STARTED,
  });
};

export const closeGetStarted = () => (dispatch: Dispatch<AppAction>) => {
  dispatch({
    type: CLOSE_GET_STARTED,
  });
};

export const toggleGetStarted = () => (dispatch: Dispatch<AppAction>) => {
  dispatch({
    type: TOGGLE_GET_STARTED,
  });
};
