import {AppAction} from './index';
import {Dispatch} from 'redux';

export const SET_MOBILE = 'NAVBAR/SET_MOBILE';
export const SET_WIDE = 'NAVBAR/SET_WIDE';
export const IS_WIDE_BAR = 'NAVBAR/IS_WIDE_BAR';

export interface NavbarState {
  isMobile: boolean;
  isWideBar: boolean;
}

const initialState: NavbarState = {
  isMobile: false,
  isWideBar: true,
};

export default (state = initialState, action: AppAction) => {
  switch (action.type) {
    case SET_MOBILE:
      return {
        ...state,
        isMobile: true,
      };
    case SET_WIDE:
      return {
        ...state,
        isMobile: false,
      };
    case IS_WIDE_BAR:
      return {
        ...state,
        isWideBar: action.payload,
      };
    default:
      return state;
  }
};

export const setMobile = () => (dispatch: Dispatch<AppAction>) => {
  dispatch({
    type: SET_MOBILE,
  });
};

export const setWide = () => (dispatch: Dispatch<AppAction>) => {
  dispatch({
    type: SET_WIDE,
  });
};

export const setIsWideBar = (isWide: boolean) => (dispatch: Dispatch<AppAction>) => {
  //console.log(isWide);
  dispatch({
    type: IS_WIDE_BAR,
    payload: isWide,
  });
};
