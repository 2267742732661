import React, {Fragment, Suspense} from 'react';
import {Provider} from 'react-redux';
import {BrowserRouter, Redirect, Route, Switch} from 'react-router-dom';
import ReactDOM from 'react-dom';
import 'react-dates/initialize';
import './i18n';
import 'react-dates/lib/css/_datepicker.css';
import './App.css';
import store from './store';
import registerServiceWorker from './registerServiceWorker';
import TStorage from './storage/TStorage';
import Gdpr from './containers/Gdpr';
import axios from 'axios';
// @ts-ignore
import trLocale from 'moment/locale/tr';
import moment from 'moment';
import "react-datepicker/dist/react-datepicker.css";

moment.locale('tr', trLocale);

// set token from query param
const urlParams = new URLSearchParams(window.location.search);
const token = urlParams.get('token');
const lang = urlParams.get('lang');
if (token) {
  TStorage.setUserToken(token);
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  window.location.href = window.location.href.replace(window.location.search, '');
}

if (lang === 'tr') {
  console.log(lang);
  TStorage.setLang('tr');
}

// @ts-ignore
TStorage.setSystem(process.env.REACT_APP_SYSTEM);

const HomepageRoute = React.lazy(() => import('./routes/HomepageRoute'));
const DashboardRoute = React.lazy(() => import('./routes/DashboardRoute'));

ReactDOM.render(
  <Provider store={store}>
    <Suspense fallback={<div />}>
      <BrowserRouter basename={`/${localStorage.getItem('i18nextLng') ?? 'tr'}`}>
        <Fragment>
          <Switch>
            <Route path="/dashboard" component={DashboardRoute} />
            <Redirect to="/dashboard/login" /> : <Route path="/" component={HomepageRoute} />
          </Switch>
          <Gdpr />
        </Fragment>
      </BrowserRouter>
    </Suspense>
  </Provider>,
  document.getElementById('root'),
);
registerServiceWorker();
